import { Tooltip } from '@material-ui/core'
import { DAISY_PRECISION } from 'assets/constants'
import daisyTokenGreen from 'assets/icons/daisyTokenGreen.svg'
import daisyTokenGrey from 'assets/icons/daisyTokenGrey.svg'
import questionMark from 'assets/icons/questionMark.svg'
import { LiquidBanner } from 'components/bannerLiquid'
import { ConfirmationButton } from 'components/buttons/confirmationButton'
import { GlobalBigButton } from 'components/buttons/globalBigButton'
import { CardHeader } from 'components/cardHeader'
import { ClaimPoolSection } from 'components/claimPoolSection'
import { CommonCard } from 'components/commonCard'
import { CommonInfoDescription } from 'components/commonInfoDescription'
import { ProgressBar } from 'components/progressBar'
import { TutorialBlock } from 'components/tutorialBlock'
import { useWithdraw } from 'hooks/claim/useWithdraw'
import { useStrictTrxBalance } from 'hooks/strict/common/useStrictTrxBalance'
import { useStrictLockupInfo } from 'hooks/strict/lockupInfo/useStrictlockupInfo'
import { useStrictPoolsBalance } from 'hooks/strict/poolsBalance/useStrictPoolsBalance'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTransactionService } from 'services/TransactionService'
import { formattedDecimals } from 'utils/tsUtils'
import { claimStateErrorMessage, claimValidateState } from './claimUtils'
import { useStyles } from './styles'

export const Claim = (): JSX.Element => {
  const classes = useStyles()
  const lockupInfo = useStrictLockupInfo()
  const balanceInTRX = useStrictTrxBalance()
  const poolsBalance = useStrictPoolsBalance()

  const { t } = useTranslation()
  const { filterTransactions } = useTransactionService()

  const pendingTransactions = filterTransactions({ statuses: ['unknown'], actions: ['claim'] })
  const formattedAvailableAmountInDaisy = formattedDecimals(lockupInfo.mainPool.availableToClaim, DAISY_PRECISION)

  const claimRequest = useWithdraw()
  const claimErrorState = claimValidateState(balanceInTRX, lockupInfo, poolsBalance)
  const claimErrorMessage = claimStateErrorMessage(claimErrorState, t)

  const canClaimLocksToken = Date.now() > lockupInfo.unlockTokenTimestamp
  const hasUnclaimed = !lockupInfo.mainPool.availableToClaim.isZero()
  const hasLocked = !lockupInfo.promoPoolShares.isZero()
  const hasClaimBonusPools =
    lockupInfo.additionalPools.some((el) => !el.availableToClaim.isZero()) ||
    lockupInfo.otherAdditionalPools.some((el) => !el.availableToClaim.isZero())

  const mainPoolAddress = lockupInfo.mainPool.poolAddress
  const mainPoolAddresses: [string] | [] = lockupInfo.mainPool.availableToClaim.isZero() ? [] : [mainPoolAddress]

  const changeButton = () => {
    switch (true) {
      case !!pendingTransactions.length:
        return <ConfirmationButton />
      case lockupInfo.availableToClaimAmount.isZero():
        return (
          <GlobalBigButton
            title={t('claim_page_claim_unavailable')}
            errorMessage={claimErrorMessage}
            isDisabled={!!claimErrorMessage}
          />
        )
      case hasLocked && !canClaimLocksToken && !hasUnclaimed:
        return !hasClaimBonusPools ? (
          <GlobalBigButton
            title=""
            isDisabled
            errorMessage={t('claim_page_token_lock', {
              time: moment(lockupInfo.unlockTokenTimestamp).format('lll Z [GMT]'),
            })}
          />
        ) : (
          <GlobalBigButton
            onClick={() => claimRequest([])}
            title={t('claim_page_claim_button')}
            errorMessage={claimErrorMessage}
            isDisabled={!!claimErrorMessage}
          />
        )
      default:
        return (
          <GlobalBigButton
            onClick={() => claimRequest(mainPoolAddresses)}
            title={t('claim_page_claim_button')}
            errorMessage={claimErrorMessage}
            isDisabled={!!claimErrorMessage}
          />
        )
    }
  }

  const now = moment.now()
  const isLockToken = lockupInfo.unlockTokenTimestamp > now
  const titleStyleClass = isLockToken ? classes.notActiveTitle : classes.title
  const daisyTokenIcon = isLockToken ? daisyTokenGrey : daisyTokenGreen
  const tooltipTitle = t('liquid_banner_info_about_lock_tokens', {
    time: moment(lockupInfo.unlockTokenTimestamp).format('lll Z [GMT]'),
  })

  const isLiquidBannerShow = Date.now() <= lockupInfo.endPromoTimestamp && Date.now() > lockupInfo.startPromoTimestamp

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        {isLiquidBannerShow && <LiquidBanner lockupInfo={lockupInfo} />}

        <CommonCard>
          <CardHeader amount={lockupInfo.mainPool.total} />
          <div className={classes.separator} />

          <div className={classes.secondaryRow}>
            <div className={classes.tooltip}>
              <span className={classes.notActiveTitle}>{t('claim_page_available_to_withdraw')}</span>
              {isLockToken && (
                <Tooltip title={tooltipTitle} placement="top">
                  <img src={questionMark} alt="questionMarkIcon" />
                </Tooltip>
              )}
            </div>
            <div className={classes.row}>
              <span className={titleStyleClass}>{formattedAvailableAmountInDaisy}</span>
              <img src={daisyTokenIcon} alt="daisyTokenIcon" />
            </div>
          </div>

          <div className={classes.progressBarContainer}>
            <ProgressBar
              claimedAmount={lockupInfo.mainPool.claimed}
              availableToClaimAmount={lockupInfo.mainPool.availableToClaim}
              totalAmount={lockupInfo.mainPool.total}
              hasLegend={true}
            />
          </div>
        </CommonCard>
        <CommonInfoDescription title={t('claim_page_vesting')} description={t('claim_page_vesting_info')} />
        <ClaimPoolSection />
        <CommonInfoDescription title={t('claim_page_vesting')} description={t('claim_page_pools_vesting_info')} />
        <div className={classes.buttonContainer}>{changeButton()}</div>
        <TutorialBlock />
      </div>
    </div>
  )
}
